import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function SelectInputDevices({ show, onConfitm, onCancel, audioDeviceId, videoDeviceId }) {
  const [audioDevices, setAudioDevices] = useState([])
  const [videoDevices, setVideoDevices] = useState([])
  const [currentAudio, setCurrentAudio] = useState(audioDeviceId)
  const [currentVideo, setCurrentVideo] = useState(videoDeviceId)
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      setAudioDevices(devices.filter(device => device.kind === 'audioinput'))
      setVideoDevices(devices.filter(device => device.kind === 'videoinput'))
    })
  }, [])
  function options(list) {
    return (
      <>
        {list.map(device => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Неизвестное устройство ${device.deviceId}`}
          </option>
        ))}
      </>
    )
  }
  function onChange(event) {
    const field = event.target.name
    const value = event.target.value
    switch (field) {
      case 'audio':
        setCurrentAudio(value)
        break
      case 'video':
        setCurrentVideo(value)
        break
      default:
    }
  }
  function saveExit() {
    onConfitm(currentAudio, currentVideo)
  }
  function cancelExit() {
    onCancel()
  }
  return (
    <Modal size="lg" centered show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Настройка</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Микрофон</Form.Label>
            <Form.Control as="select" name="audio" custom value={currentAudio || ''} onChange={onChange}>
              {options(audioDevices)}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Камера</Form.Label>
            <Form.Control as="select" name="video" custom value={currentVideo || ''} onChange={onChange}>
              >{options(videoDevices)}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="text-right">
        <Button variant="outline-primary" onClick={saveExit}>
          Применить
        </Button>
        <Button variant="outline" onClick={cancelExit}>
          Отмена
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

SelectInputDevices.propTypes = {
  show: PropTypes.bool,
  onConfitm: PropTypes.func,
  onCancel: PropTypes.func,
  audioDeviceId: PropTypes.string,
  videoDeviceId: PropTypes.string
}

export default SelectInputDevices
