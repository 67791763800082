import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import ChatList from '../components/chat/list'
import ChatForm from '../components/chat/form'
import ClonedVideo from '../components/cloned-video'
import { message, error } from '../notification'
import ObservableSocket from '../utils/observable-socket'
import {
  CameraSubscription,
  MutedSubscription as ScreenSubscription,
  CameraControlSubscription
} from '../components/subscription'
import Publication from '../components/publication'
import SelectInputDevices from '../components/select-input-devices'

import Navbar from 'react-bootstrap/Navbar'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'

import MemoizedYouTube from '../components/memoized-youtube'
import config from '../config'
import {saveMeeting} from '../history'
import './meeting.scss'

class Meeting extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      camera: false,
      cameraVideo: true,
      cameraAudio: true,
      meeting: null,
      role: '',
      participant: null,
      speaker: null,
      started: false,
      participants: [],
      chat: [],
      presentationType: 'none',
      presentationData: null,
      currentTab: 'meeting',
      audioDeviceId: localStorage.getItem('audioinput'),
      videoDeviceId: localStorage.getItem('videoinput'),
      setupDialogVisible: false
    }
    this.onStart = this.onStart.bind(this)
    this.onPostMessage = this.onPostMessage.bind(this)
    this.onStop = this.onStop.bind(this)
    this.onSpeaking = this.onSpeaking.bind(this)
    this.onStoppedSpeaking = this.onStoppedSpeaking.bind(this)
    this.toggleAudio = this.toggleAudio.bind(this)
    this.toggleVideo = this.toggleVideo.bind(this)
    this.selectedTab = this.selectedTab.bind(this)
    this.setupDevices = this.setupDevices.bind(this)
    this.onConfirmSetupDevices = this.onConfirmSetupDevices.bind(this)
    this.onCancelSetupDevices = this.onCancelSetupDevices.bind(this)
  }

  componentDidMount() {
    this.connect()
  }

  componentDidUpdate(prevProps) {
    const sameCode = prevProps.match.params.code === this.props.match.params.code
    if (!sameCode) {
      this.connect()
    }
  }

  componentWillUnmount() {
    setTimeout(() => this.signalSocket.disconnect(), 300)
  }

  onStart() {
    this.setState({
      camera: this.state.participant.code + '-camera'
    })
  }

  onStop() {
    this.setState(
      {
        camera: null
      },
      () => this.props.history.push('/')
    )
  }

  onPostMessage(body) {
    this.signalSocket.sendMessage('message', { body })
  }

  connect() {
    const code = this.props.match.params.code
    this.signalSocket = new ObservableSocket(`${config.rtcEndPoint}/${code}`)
    this.signalSocket.reconnect = false
    this.signalSocket.open$.subscribe(() => {
      message('Подключено')
    })

    this.signalSocket.error$.subscribe(() => {
      error('Ошибка сокета')
    })

    this.signalSocket.close$.subscribe(() => {
      error('Внезапное отключение')
    })

    this.signalSocket.message$.subscribe(message => {
      console.log(message.id)
      switch (message.id) {
        case 'welcome':
          {
            const { participant, meeting, chat = [] } = message
            const { presentationType = 'none', presentationData } = meeting
            const currentTab = presentationType === 'none' ? 'meeting' : 'presentation'
            this.setState(
              {
                participant,
                role: participant.role,
                meeting,
                chat,
                presentationType,
                presentationData,
                currentTab
              },
              () => {
                if (this.state.role === 'participant') {
                  this.onStart()
                }
              }
            )
            saveMeeting(code, meeting.name)
          }
          break
        case 'message':
          {
            const { id, ...rest } = message
            const chat = [...this.state.chat]
            chat.push(rest)
            this.setState({
              chat
            })
          }
          break
        case 'participants':
          {
            const participants = message.data.map(participant => {
              participant.camera = participant.channels.find(c => c.includes('camera'))
              participant.screen = participant.channels.find(c => c.includes('screen'))
              return participant
            })
            const speaker = participants.find(p => p.role === 'speaker')
            const participant = participants.find(p => p.code === this.state.participant.code)
            this.setState({
              participant,
              speaker,
              participants
            })
          }
          break
        case 'startedSpeaking':
        case 'stoppedSpeaking':
          {
            const channel = message.channel
            const speaking = message.id === 'startedSpeaking'
            const participants = this.state.participants.map(participant => {
              if (participant.camera !== channel) {
                return participant
              } else {
                return { ...participant, speaking }
              }
            })
            const speaker = participants.find(p => p.role === 'speaker')
            const participant = participants.find(p => p.code === this.state.participant.code)
            this.setState({
              participant,
              speaker,
              participants
            })
          }
          break
        case 'presentation':
          {
            const { presentationType, presentationData } = message
            const currentTab = presentationType === 'none' ? 'meeting' : 'presentation'
            this.setState({
              presentationType,
              presentationData,
              currentTab
            })
          }
          break
        case 'stop':
          this.setState(
            {
              camera: null,
              participants: []
            },
            () => {
              this.props.history.push('/')
            }
          )
          break

        default:
      }
    })
    this.logging = false && process.env.NODE_ENV !== 'production'
    this.signalSocket.connect()
  }

  toggleAudio() {
    this.setState(state => ({ ...state, cameraAudio: !state.cameraAudio }))
  }

  toggleVideo() {
    this.setState(state => ({ ...state, cameraVideo: !state.cameraVideo }))
  }

  selectedTab(tab) {
    this.setState({
      currentTab: tab
    })
  }

  error(error) {
    error(error)
  }

  log(message) {
    alert(message)
  }

  onSpeaking() {
    this.signalSocket.sendMessage('startedSpeaking', {
      channel: this.state.camera
    })
  }

  onStoppedSpeaking() {
    this.signalSocket.sendMessage('stoppedSpeaking', {
      channel: this.state.camera
    })
  }

  setupDevices() {
    this.setState(state => ({ ...state, setupDialogVisible: true }))
  }

  onConfirmSetupDevices(audioDeviceId, videoDeviceId) {
    localStorage.setItem('audioinput', audioDeviceId)
    localStorage.setItem('videoinput', videoDeviceId)
    this.setState(
      state => ({ ...state, camera: null, setupDialogVisible: false, audioDeviceId, videoDeviceId }),
      () => {
        if (this.state.role === 'participant') {
          this.setState(state => ({ ...state, camera: this.state.participant.code + '-camera' }))
        }
      }
    )
  }

  onCancelSetupDevices() {
    this.setState(state => ({ ...state, setupDialogVisible: false }))
  }

  speakerCamera() {
    const speaker = this.state.speaker || {}
    const channel = speaker.camera || null

    const onConnected = video => {
      this.setState(state => ({ ...this.state, speakerCameraVideoStream: video.captureStream() }))
    }

    if (!channel) {
      return (
        <CameraSubscription
          key="empty-speaker-camera"
          displayName={this.state.meeting.speaker}
          socket={this.signalSocket}
        />
      )
    }
    return (
      <CameraSubscription
        key={speaker.code + '-' + channel + '-view'}
        channel={channel}
        displayName={speaker.name}
        speaking={speaker.speaking}
        socket={this.signalSocket}
        logging={this.logging}
        onConnected={onConnected}
      />
    )
  }

  speakerScreen() {
    const speaker = this.state.speaker || {}
    const channel = speaker.screen || null
    if (!channel) {
      return <ScreenSubscription key="empty-speaker-screen" displayName={speaker.name} socket={this.signalSocket} />
    }
    return (
      <ScreenSubscription
        key={speaker.code + '-' + channel + '-view'}
        channel={channel}
        displayName={speaker.name}
        speaking={speaker.speaking}
        socket={this.signalSocket}
        logging={this.logging}
      />
    )
  }

  myCameraSubscription() {
    if (this.state.role === 'guest') return
    const participant = this.state.participant || {}
    const channel = participant.camera || null
    if (!channel) {
      return (
        <CameraSubscription
          key="my-camera-subscription"
          displayName={participant.name}
          audio={this.state.cameraAudio}
          video={this.state.cameraVideo}
          toggleAudio={this.toggleAudio}
          toggleVideo={this.toggleVideo}
        />
      )
    }

    return (
      <CameraControlSubscription
        key="my-camera-subscription"
        channel={channel}
        displayName={participant.name}
        socket={this.signalSocket}
        logging={this.logging}
        audio={this.state.cameraAudio}
        video={this.state.cameraVideo}
        toggleAudio={this.toggleAudio}
        toggleVideo={this.toggleVideo}
      />
    )
  }

  myCameraPublication() {
    if (this.state.camera) {
      return (
        <Publication
          key="my-camera-publication"
          channel={this.state.camera}
          socket={this.signalSocket}
          logging={this.logging}
          audio={this.state.cameraAudio}
          video={this.state.cameraVideo}
          speakingThreshold={60}
          audioDeviceId={this.state.audioDeviceId}
          videoDeviceId={this.state.videoDeviceId}
          onSpeaking={this.onSpeaking}
          onStoppedSpeaking={this.onStoppedSpeaking}
        />
      )
    }
  }

  participantCamera(participant) {
    const channel = participant.camera || null
    const key = 'camera-' + participant.code

    if (!channel) {
      return <CameraSubscription key={key} displayName={participant.name} />
    }

    return (
      <CameraSubscription
        key={key}
        channel={channel}
        displayName={participant.name}
        socket={this.signalSocket}
        logging={this.logging}
      />
    )
  }

  meetingParticipants() {
    const sorted = [...this.state.participants]
      .filter(e => e.role === 'participant')
      .filter(e => e.code !== this.state.participant.code)
      .sort((a, b) => {
        if (a.online !== b.online) {
          return a.online - b.online
        } else {
          return a.name.localeCompare(b.name)
        }
      })
    return sorted.map(e => this.participantCamera(e))
  }

  myChat() {
    return (
      <div className="p-4">
        {this.state.participant.role === 'participant' && <ChatForm onPostMessage={this.onPostMessage} />}
        <ChatList messages={this.state.chat} reverse />
      </div>
    )
  }

  myPresentation() {
    if (this.state.presentationType === 'screen') {
      return <div className="presentation">{this.speakerScreen()}</div>
    } else if (this.state.presentationType === 'head') {
      return (
        <div className="presentation">
          <ClonedVideo source={this.state.speakerCameraVideoStream} />
        </div>
      )
    } else if (this.state.presentationType === 'page' && this.state.presentationData) {
      const url =
        this.state.presentationData.indexOf('http') === 0
          ? this.state.presentationData
          : 'http://' + this.state.presentationData
      return <iframe title="Presentation" className="presentation" src={url} frameBorder="0" />
    } else if (this.state.presentationType === 'youtube' && this.state.presentationData) {
      const videoId = String(this.state.presentationData.split('=').join('/').split('/').slice(-1))
      return <MemoizedYouTube videoId={videoId} />
    } else return
  }

  render() {
    if (!this.state.participant) {
      return <section className="connecting">...Подождите, пока я подключаюсь к серверу...</section>
    }

    return (
      <div className="meeting">
        <Navbar bg="light" expand="lg" className="header">
          <Navbar.Brand>{this.state.meeting.name}</Navbar.Brand>
          <Button
            className="ml-auto mr-2"
            variant="link"
            onClick={this.setupDevices}
            disabled={this.state.role !== 'participant'}
          >
            <i className="fa fa-cog mr-2" /> Настройка
          </Button>
          <Button className="mx-2" variant="link" href="/">
            <i className="fas fa-sign-out-alt mr-2" />
            Покинуть встречу
          </Button>

        </Navbar>
        <SelectInputDevices
          show={this.state.setupDialogVisible}
          audioDeviceId={this.state.audioDeviceId}
          videoDeviceId={this.state.videoDeviceId}
          onConfitm={this.onConfirmSetupDevices}
          onCancel={this.onCancelSetupDevices}
        />
        <Tabs activeKey={this.state.currentTab} onSelect={this.selectedTab} variant="pills">
          <Tab eventKey="meeting" title="Встреча" className="p-4">
            <p>
              Количество участников в комнате: {this.state.participants.filter(e => e.role === 'participant').length}.
            </p>
            <div className="participants">
              {this.speakerCamera()}
              {this.myCameraSubscription()}
              {this.meetingParticipants()}
            </div>
            {this.myCameraPublication()}
          </Tab>
          <Tab eventKey="chat" title="Чат" className="p-4">
            {this.state.participant.role === 'participant' && <ChatForm onPostMessage={this.onPostMessage} />}
            <ChatList messages={this.state.chat} reverse />
          </Tab>
          <Tab
            eventKey="presentation"
            title="Презентация"
            className="p-4"
            disabled={!this.state.presentationType || this.state.presentationType === 'none'}
          >
            {this.myPresentation()}
          </Tab>
        </Tabs>
      </div>
    )
  }
}

Meeting.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default withRouter(Meeting)
