
function saveMeeting(code, name) {
  const meetings = getSavedMeetings()
  if (!meetings.find(e => e.code === code)) {
    meetings.push({code, name})
  }
  localStorage.setItem('history', JSON.stringify(meetings))
}

function getSavedMeetings() {
  try {
    return JSON.parse(localStorage.getItem('history')) || []
  } catch {
    return []
  }
}

export {saveMeeting, getSavedMeetings}