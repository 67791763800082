import React from 'react'
import {getSavedMeetings} from '../history'
import ListGroup from 'react-bootstrap/ListGroup'

function RecentMeetings() {
  const recent = getSavedMeetings().slice(-5)
  if (!recent.length) return null
  return (
    <>
    <hr className="my=5"/>
    <h3 className="display-5">Недавние встречи</h3>
      <ListGroup className="bg-light">
      {recent.map(({code, name}) => <ListGroup.Item key={code} action href={'#/meeting/' + code}>{name}</ListGroup.Item>)}
    </ListGroup>
    </>
  )
}
function rootView() {
  return (
    <div className="v-layout">
      <div className="jumbotron jumbotron-fluid bg-primary text-white">
        <div className="container">
          <h1 className="display-4">VEP-meeting</h1>
          <p className="lead">Вы должны получить письмо со ссылкой, чтобы использовать сервис</p>
          <RecentMeetings />
        </div>
      </div>
    </div>
  )
}
export default rootView
