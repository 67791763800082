import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
function ChatForm({ onPostMessage }) {
  const [message, setMessage] = useState('')
  function onSubmit(event) {
    event.preventDefault()
    onPostMessage(message)
    setMessage('')
  }

  function onChange(event) {
    setMessage(event.target.value)
  }

  return (
    <Form onSubmit={onSubmit} className="mb-3">
      <InputGroup>
        <Form.Control type="text" placeholder="Чат" value={message} onChange={onChange} style={{ height: 'auto' }} />
        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={onSubmit}>
            OK
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  )
}
ChatForm.propTypes = {
  onPostMessage: PropTypes.func
}
export default memo(ChatForm)
