import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class ClonedVideo extends PureComponent {
  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount() {
    this.updateVideo()
  }

  componentDidUpdate() {
    this.updateVideo()
  }
  updateVideo() {
    if (this.video.current && this.props.source) {
      this.video.current.srcObject = this.props.source
    }
  }
  render() {
    return <video muted autoPlay ref={this.video} poster={this.props.poster} />
  }
}

ClonedVideo.propTypes = {
  source: PropTypes.object,
  poster: PropTypes.string
}

export default ClonedVideo
