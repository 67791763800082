import React, { memo } from 'react'
import YouTube from 'react-youtube'

function YoutubePlayer({videoId}) {
  return (
    <YouTube
      videoId={videoId}
      containerClassName="presentation"
      className="presentation"
      opts={{
        playerVars: {
          autoplay: 1,
          rel: 0
        }
      }}
    />
  )
}
export default memo(YoutubePlayer)
